import { ExtraField, IExtraField, hideField, hideFieldLabel } from './ExtraField'
import { IClientChanges } from '../../../services/ClientsService'
import { InfoItem } from './InfoItem'
import { Card } from 'antd-mobile'
import styles from './ExtraFieldGroups.module.scss'
import cn from 'classnames'
import React from 'react'

export interface IExtraFieldGroup {
  id: string
  name: string
}

interface GroupProps {
  group: IExtraFieldGroup
  list: IExtraField[]
  changes: IClientChanges
  onChange: (changes: IClientChanges) => void
}

const ExtraFieldGroup = ({ group, list, changes, onChange }: GroupProps) => <div className="flex col">
  <Card title={group.name}>
    {list.map(({ id, name, value, metadata }: IExtraField) =>
      !hideField.includes(metadata?.fieldType) && <InfoItem
        key={id}
        label={!hideFieldLabel.includes(metadata?.fieldType) ? name : null}
        value={<ExtraField
          value={id in changes ? changes[id] : value}
          onChange={value => onChange({ ...changes, [id]: value })}
          metadata={metadata}
        />}
      />
    )}
  </Card>
</div>;

interface Props {
  extraFields: IExtraField[]
  extraFieldGroups: IExtraFieldGroup[]
  changes: IClientChanges
  onChange: (changes: IClientChanges) => void
}

export const ExtraFieldGroups = ({ extraFields, extraFieldGroups, changes, onChange }: Props) => {
  const groupedExtraFields = React.useMemo((): [IExtraFieldGroup, IExtraField[]][] => {
    const groups = {} as { [key: string]: IExtraField[] };
    for (const d of extraFields) {
      const k = d.metadata.groupId;
      groups[k] = groups[k] || [];
      groups[k].push(d);
    }
    return Object.entries(groups)
      .sort((a, b) => a[0].localeCompare(b[0]))
      .map(([groupId, fieldsList]: [IExtraFieldGroup["id"], IExtraField[]]) => [
        {
          id: groupId,
          name: extraFieldGroups.find(d => d.id == groupId)?.name || ''
        },
        fieldsList
      ]);
  }, [extraFields.map(f => JSON.stringify([f.id, f.value])).join('')]);
  
  return <div className={cn("flex col", styles.root)}>
    {groupedExtraFields.map(([group, fieldsList]: [IExtraFieldGroup, IExtraField[]]) =>
      <ExtraFieldGroup
        key={group.id}
        group={group}
        list={fieldsList}
        changes={changes}
        onChange={onChange}
      />
    )}
  </div>
}
