import styles from './ListItem.module.scss'
import React from 'react'
import cn from 'classnames'

interface Props {
  title: React.ReactNode
  description?: React.ReactNode
  icon: React.ReactNode
  rightIcon?: React.ReactNode
  highlight?: boolean
  onClick?: (e: any) => void
}

export const ListItem = ({
  icon, title, description, rightIcon, highlight,
  onClick=(e: any)=>{}
}: Props) => {
  return <div onClick={onClick} className={styles.root}>
    <div className={cn(styles.wrapper, highlight && styles.highlight)}>
      <div className={styles.icon}> {icon} </div>
      <div className="flex col">
        <div className={styles.title}> {title} </div>
        <div className={styles.description}> {description} </div>
      </div>
      <div className={styles.righticon}> {rightIcon} </div>
    </div>
  </div>
}
