import React from 'react'
import { debounce } from '../../lib/utilsCommon'
import { useNavigate } from '../../hooks/useNavigate'
import { useLoader } from '../../hooks/useLoader'
import { ScreenLayout } from '../shared/ui/ScreenLayout'
import { ListItem } from '../shared/ui/ListItem'
import { Avatar, SearchBar, Calendar } from 'antd-mobile'
import { RightOutline } from 'antd-mobile-icons'
import { observer } from 'mobx-react'
import { clientsService } from '../../services/ClientsService'
import { formatClientName, formatClientPhoneNumber, formatClientEmail } from './ScreenClientCard'
import { store } from '../../store'
import { useOnMount } from '../../hooks/useOnMount'
import { t } from '../../config/i18n'

export const ScreenClients = observer(() => {
  const navigate = useNavigate()
  const [loading, load] = useLoader(async () => {
    if (!store.clients.list) await clientsService.loadList();
  })
  useOnMount(load);
  const [searchValue, setSearchValue] = [
    store.clients.searchValue || '',
    (v: string) => { store.clients.searchValue = v; }
  ];
  const search = React.useCallback(debounce(setSearchValue, 150), []);
  
  const clients = React.useMemo(() =>
    (store.clients.list || []).filter(cl => [
      formatClientName(cl),
      formatClientPhoneNumber(cl),
      formatClientEmail(cl)
    ].some(val => val.toLowerCase().includes(searchValue.toLowerCase())))
  , [searchValue, store.clients.list]);
  //console.log({ clients, searchValue, loading, load })
  
  return <ScreenLayout
    title={t.nav.clients}
    className=""
    loading={loading}
    onBack={() => navigate('/appointments')}
    onAction={() => {}}
  >
    <div className="pa3 bg-white border-b">
      <SearchBar placeholder={t.common.search} value={searchValue} onChange={search}/>
    </div>
    {clients.map(client => <ListItem
      key={client.id}
      icon={<Avatar src='' style={{ '--size': '20px' }}/>}
      rightIcon={<RightOutline/>}
      onClick={() => navigate('/client-card/:id', { id: client.id } as never)}
      title={formatClientName(client)}
      description={formatClientPhoneNumber(client)}
    />)}
  </ScreenLayout>
})
