import { config } from '../config/config'
import { loggerNamespace } from './Logger'

class Rpc {
  public url = `${config.domain.API_SERVER}/rpc`
  public user = ''
  public token = ''
  private inc = 1
  private logger = loggerNamespace('Rpc')
  private subscribers: TSubscriber[] = []

  public call = async <T>(method: string, params: TObjectAny): Promise<{ error?: unknown, result?: T }> => {
    const payload: TObjectAny = {
      jsonrpc: '2.0',
      id: this.inc++,
      method,
      params,
    }

    if (this.user && this.token) {
      payload.cred = {
        user: this.user,
        token: this.token,
      }
    }

    try {
      const { error, result } = await (await fetch(this.url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      })).json()

      if (error) {
        this.logger.error(error)
        this.emitError(error?.message || 'Unknown error')
        return { error }
      }
      return { result }

    } catch (e: Error | any) {
      this.logger.error(e)
      this.emitError(e?.message || 'Unknown error')
      return { error: e.message ?? e.toString() }
    }
  }

  public onError = (fn: TSubscriber) => {
    this.subscribers.push(fn)
    return () => { this.subscribers = this.subscribers.filter(i => i !== fn) }
  }

  private emitError = (error: string) => {
    this.subscribers.forEach(fn => fn(error))
  }
}

type TSubscriber = (error: string) => void

const rpc = new Rpc()
export { rpc }
