import { NavigateFunction, Location } from 'react-router'
import { makeObservable } from 'mobx'

class RouterService {
  public navigate: NavigateFunction = () => {}
  public location?: Location

  public constructor() {
    makeObservable(this, {
      navigate: true,
      location: true,
    })
  }
}

const routerService = new RouterService()
export { routerService }
