import React from 'react'
import { useNavigate, useParams } from 'react-router'
import { ScreenLayout } from '../shared/ui/ScreenLayout'
import { PersonInfo } from '../shared/ui/PersonInfo'
import { ClientInfo } from './screenClientCard/ClientInfo'
import {
  CalendarOutline, ClockCircleOutline, ContentOutline, EnvironmentOutline,
  PhoneFill, TextOutline, RightOutline
} from 'antd-mobile-icons'
import { observer } from 'mobx-react'
import { store } from '../../store'
import { clientsService } from '../../services/ClientsService'
import { useOnMount } from '../../hooks/useOnMount'
import { t } from '../../config/i18n'

export const formatClientName = (cl: any) => [
  cl.surname, cl.name, cl.middle_name
].filter(v => v).join(' ')

export const formatClientPhoneNumber = (cl: any) =>
  !cl.phone || !cl.phone.length
  ? t.client.noPhone
  : [
    '+', cl.phone[0].country_code, ' ',
    cl.phone[0].area_code, ' ',
    cl.phone[0].number
  ].filter(v => v).join('');

export const formatClientEmail = (cl: any) =>
  !cl.email || !cl.email.length
  ? t.client.noEmail
  : cl.email[0];
  
export const formatClientBirthday = (cl: any) =>
  !cl.birthday
  ? t.client.noBirthday
  : new Date(cl.birthday).toLocaleDateString(t.locale, {
    
  })
  
export const formatClientAddress = (cl: any, business: any) => {
  const { showAddress, showHouseNumber, showKupatHolim } = (business?.backofficeConfiguration as TObjectAny) || {};
  const { address, houseNumber, kupatHolim } = (cl as TObjectAny) || {};
  return showAddress ? address
    : showHouseNumber ? houseNumber
    : showKupatHolim ? kupatHolim.name
    : ''; 
}

export const ScreenClientCard = observer(() => {
  const navigate = useNavigate()
  const { id } = useParams();
  
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    setLoading(true);
    if (id) clientsService.loadClient(id).finally(() => setLoading(false));
  }, [id]);
  
  const client = store.clients.client;
  const appointment = store.clients.appointment;
  const { fieldGroups, fieldPresets, fieldsList } = store.profile;
  //console.log({ appointment, client: JSON.parse(JSON.stringify(client)) })
  
  return <ScreenLayout
    title={t.client.info}
    className=""
    onBack={() => navigate(-1)}
    onAction={() => {}}
    loading={loading}
  >
    {!client ? <div> not found </div> : <React.Fragment>
      <PersonInfo
        avatarSrc={client.client.iconURL}
        name={formatClientName(client.client)}
        description={client.client.profileID}
      />
      <ClientInfo
        birthday={formatClientBirthday(client.client)}
        gender={t.common.sexName[client.client.sex]}
        phoneNumber={formatClientPhoneNumber(client.client)}
        email={formatClientEmail(client.client)}
        onChange={clientsService.updateExtraFields}
        extraFieldGroups={(fieldGroups || [])}
        extraFields={(client.client?.extraFields || [])
          .map((f: any) => ({
            id: f.fieldID,
            name: f.fieldName,
            value: f.value,
            metadata: (fieldsList || []).map((d: any) => d.field).find((d: any) => d?.id == f.fieldID)
          }))
          .filter(d => d.metadata)
        }
      />
    </React.Fragment>}
  </ScreenLayout>
})
