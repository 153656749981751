import React from 'react';

export const useLoader = (fn: Function) : [boolean, (...args: any) => Promise<void>] => {
  const [loading, setLoading] = React.useState(false);
  
  return [loading, async (...args: any) => {
    try {
      setLoading(true);
      await fn(...args);
    } finally {
      setLoading(false);
    }
  }]
}
