import { loggerNamespace } from '../lib/Logger'
import { rpc } from '../lib/Rpc'
import { store } from '../store'
import { authService } from './AuthService'
import { IProfileGetProfile } from '../interfaces/rpc/IProfileGetProfile'
import { IBusinessGetProfileById } from '../interfaces/rpc/IBusinessGetProfileById'
import { IFieldGetFieldsList } from '../interfaces/rpc/IFieldGetFieldsList'
import { IFieldGetFieldGroups } from '../interfaces/rpc/IFieldGetFieldGroups'
import { IFieldGetFieldPresets } from '../interfaces/rpc/IFieldGetFieldPresets'
import { IResourceGetBusinessResources } from '../interfaces/rpc/IResourceGetBusinessResources'

type IResource = IResourceGetBusinessResources[number]

class ProfileService {
  private logger = loggerNamespace('ProfileService')

  public loadProfile = async () => {
    const { error, result } = await rpc.call<IProfileGetProfile>('profile.get_profile', {
      id: authService.authData.userInfo?.user_id,
      with_business_info: true,
    })
    this.logger.info('profile.get_profile', { error, result })
    if (result) {
      store.profile.profile = result
      const businessId = Object.keys(result.businessData)[0]
      await this.setBusinessId(businessId)
      await this.loadResources()
      this.setResource(
        store.profile.resources[0] ||
        (store.profile.business?.business?.resources || []).find(
          d => d.profile.profileID == store.profile.profile?.profile?.id
        )
      )
    }
  }

  public setBusinessId = async (businessId: string) => {
    store.ui.loading = true
    store.profile.businessId = businessId
    await this.loadBusiness()
    await this.loadExtraFields()
    store.ui.loading = false
  }

  private loadBusiness = async () => {
    if (store.profile.businessId) {
      const { error, result } = await rpc.call<IBusinessGetProfileById>('business.get_profile_by_id', {
        business: { id: store.profile.businessId },
        skip_worker_sorting: true,
      })
      this.logger.info('business.get_profile_by_id', { error, result })
      if (result) {
        store.profile.business = result
      }
    }
  }
  
  private loadExtraFields = async () => {
    if (!store.profile.businessId) return;
    
    {
      const { error, result } = await rpc.call<IFieldGetFieldGroups>('field.get_field_groups', {
        business: { id: store.profile.businessId }
      })
      this.logger.info('field.get_field_groups', { error, result })
      if (result) {
        store.profile.fieldGroups = result
      }
    }
    
    {
      const { error, result } = await rpc.call<IFieldGetFieldGroups>('field.get_field_presets', {
        business: { id: store.profile.businessId }
      })
      this.logger.info('field.get_field_presets', { error, result })
      if (result) {
        store.profile.fieldPresets = result
      }
    }
    
    {
      const { error, result } = await rpc.call<IFieldGetFieldsList>('field.get_fields_list', {
        business: { list: [store.profile.businessId] },
        field: { active: true }
      })

      this.logger.info('field.get_fields_list', { error, result })
      if (result) {
        store.profile.fieldsList = result.find((d: any) =>
          d.id == store.profile.businessId
        )?.value || []
      }
    }
  }
  
  public loadResources = async () => {
    if (!store.profile.businessId) return;
    
    store.profile.resources = [];
    store.profile.businessIdByResourceId = {}
    for (const { businessId, resourceIds } of (store.profile.profile?.businessResourceIds || [])) {
      const { error, result } = await rpc.call<IResourceGetBusinessResources>('resource.get_business_resources', {
        business: { id: businessId }
      })
      
      this.logger.info('resource.get_business_resources', { error, result })
      if (result) {
        for (const item of result.filter((r: IResource) => resourceIds.includes(r.id))) {
          store.profile.resources.push(item);
          store.profile.businessIdByResourceId[item.id] = businessId;
        }
      }
    }
  }
  
  public setResource = async (resource: IResource) => {
    await this.setBusinessId(store.profile.businessIdByResourceId[resource.id])
    store.profile.resource = resource
  }
}

const profileService = new ProfileService()
export { profileService }
