import React from 'react'
import { NavBar, Space } from 'antd-mobile'
import styles from './Header.module.scss'

interface Props {
  title: string
  actionIcon?: React.ReactNode
  onAction?: () => void
  onBack?: () => void
}

export const ScreenLayoutHeader = ({ title, actionIcon, onAction, onBack }: Props) => {
  const right = React.useMemo(() => {
    if (actionIcon && onAction) {
      return (
        <div className={styles.action} onClick={onAction}>
          <Space style={{ '--gap': '16px' }}>
            {actionIcon}
          </Space>
        </div>
      )
    }
    return null
  }, [actionIcon, onAction])

  return (
    <div className={styles.root}>
      <NavBar
        back={onBack ? '' : null}
        onBack={onBack}
        right={right}
      >
        {title}
      </NavBar>
    </div>
  )
}
