const locale = 'ru';

const antd = {
  locale,
  common: {
    confirm: 'Подтвердить',
    cancel: 'Отменить',
    loading: 'Загрузка'
  },
  Calendar: {
    markItems: ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'],
    renderYearAndMonth: (year: any, month: any) => `${year}/${month}`
  },
  Cascader: {
    placeholder: 'Selecting'
  },
  Dialog: {
    ok: 'OK'
  },
  ErrorBlock: {
    default: {
      title: 'Что-то пошло не так',
      description: ''
    },
    busy: {
      title: 'Не удалось загрузить данные',
      description: ''
    },
    disconnected: {
      title: '',
      description: ''
    },
    empty: {
      title: '',
      description: ''
    }
  },
  Form: {
    required: 'Обязательное поле',
    optional: 'Опционально',
    defaultValidateMessages: {
      default: 'Введены неверные данные в поле ${label}',
      required: 'Необходимо заполнить ${label}',
      enum: 'Допустимые значения для ${label}: ${enum}',
      whitespace: '${label} не может быть пустым',
      date: {
        format: '',//'${label} date format is invalid',
        parse: '',//'${label} cannot be converted to a date',
        invalid: '',//'${label} is an invalid date'
      },
      types: {
        string: '',//'${label} is not a valid ${type}',
        method: '',//'${label} is not a valid ${type}',
        array: '',//'${label} is not a valid ${type}',
        object: '',//'${label} is not a valid ${type}',
        number: '',//'${label} is not a valid ${type}',
        date: '',//'${label} is not a valid ${type}',
        boolean: '',//'${label} is not a valid ${type}',
        integer: '',//'${label} is not a valid ${type}',
        float: '',//'${label} is not a valid ${type}',
        regexp: '',//'${label} is not a valid ${type}',
        email: '',//'${label} is not a valid ${type}',
        url: '',//'${label} is not a valid ${type}',
        hex: '',//'${label} is not a valid ${type}'
      },
      string: {
        len: 'Допустимая длина ${label}: ${len} символов',
        min: 'Допустимая длина ${label}: от ${len} символов',
        max: 'Допустимая длина ${label}: до ${len} символов',
        range: 'Допустимая длина ${label}: от ${len} до ${max} символов'
      },
      number: {
        len: '',//'${label} must be equal to ${len}',
        min: '',//'${label} must be minimum ${min}',
        max: '',//'${label} must be maximum ${max}',
        range: '',//'${label} must be between ${min}-${max}'
      },
      array: {
        len: '',//'Must be ${len} ${label}',
        min: '',//'At least ${min} ${label}',
        max: '',//'At most ${max} ${label}',
        range: '',//'The amount of ${label} must be between ${min}-${max}'
      },
      pattern: {
        mismatch: '',//'${label} does not match the pattern ${pattern}'
      }
    }
  },
  ImageUploader: {
    uploading: 'Загрузка...',
    upload: 'Загрузить'
  },
  InfiniteScroll: {
    noMore: '',//'No more',
    failedToLoad: 'Не удалось загрузить',
    retry: 'Повторить попытку'
  },
  Mask: {
    name: '',//'Mask'
  },
  Modal: {
    ok: 'OK'
  },
  PullToRefresh: {
    pulling: '',//'Scroll down to refresh',
    canRelease: '',//'Release to refresh immediately',
    complete: '',//'Refresh successful'
  },
  Slider: {
    name: '',//'Slider'
  },
  Stepper: {
    decrease: 'увеличить',
    increase: 'уменьшить'
  },
  Switch: {
    name: '',//'Switch'
  }
};


export const ru = {
  antd,
  locale,
  common: {
    download: 'Скачать',
    cancel: 'Отменить',
    confirm: 'Подтвердить',
    yes: 'Да',
    no: 'Нет',
    general: 'Общее',
    search: 'Поиск',
    sex: 'Пол',
    sexName: {
      MALE: 'Мужской',
      FEMALE: 'Женский',
      NOT_SPECIFIED: 'Не указан'
    },
    minutesShort: 'мин.',
    ehr: 'Мед. карта',
    birthday: 'Дата рождения',
    phoneNumber: 'Номер телефона',
    email: 'Электронная почта',
    appointmentInfo: 'О визите',
    info: 'Инфо',
    mainInfo: 'Основная информация',
    additionalInfo: 'Доп. информация',
    saveChanges: 'Сохранить изменения'
  },
  auth: {
    title: 'Кабинет доктора',
    formTitle: 'Авторизация',
    login: 'Логин',
    password: 'Пароль',
    loginButton: 'Войти',
    logoutButton: 'Выйти из приложения'
  },
  nav: {
    profile: 'Профиль',
    clients: 'Пациенты',
    appointments: 'Записи на прием',
  },
  appointment: {
    myProgress: 'Мой прогресс',
    comment: 'Отзыв о приеме',
    noNotes: 'Комментарий не указан',
    noAddress: 'Адрес не указан',
    list: 'Записи на прием',
    info: 'Информация о записи',
    cancelQuestion: 'Отменить данную запись на прием?',
    cancelled: 'Запись была отменена',
    completed: 'Прием пациента завершен',
    duration: 'Длительность'
  },
  client: {
    info: 'Информация о пациенте',
    list: 'Пациенты',
    id: 'ID пациента',
    noBirthday: 'Дата не указана',
    noEmail: 'Эл. почта не указана',
    noPhone: 'Номер телефона не указан'
  },
  dates: {
    months: [
      'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
      'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
    ],
    weekdays: [
      'Понедельник', 'Вторник', 'Среда', 'Четверг',
      'Пятница', 'Суббота', 'Воскресенье'
    ],
    weekdaysShort: ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС']
  },
  inputs: {
    textPlaceholder: 'Введите текст',
    datePlaceholder: 'Укажите дату',
    filePlaceholder: 'Без файла',
    dropdownPlaceholder: 'Не выбрано',
    dropdownNoElements: 'Нет элементов',
  },
  customStep: {
    textChangeSlot: 'Вы действительно хотите поменять следующие слоты местами?',
    confirm: 'Подтвердить',
    cancel: 'Отменить',
  },
}
