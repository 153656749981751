import { makeAutoObservable } from 'mobx'
import { IProfileGetProfile } from '../interfaces/rpc/IProfileGetProfile'
import { IBusinessGetProfileById } from '../interfaces/rpc/IBusinessGetProfileById'
import { IFieldGetFieldsList } from '../interfaces/rpc/IFieldGetFieldsList'
import { IFieldGetFieldGroups } from '../interfaces/rpc/IFieldGetFieldGroups'
import { IFieldGetFieldPresets } from '../interfaces/rpc/IFieldGetFieldPresets'
import { IResourceGetBusinessResources } from '../interfaces/rpc/IResourceGetBusinessResources'

export class ProfileStore {
  public profile: IProfileGetProfile | null = null
  public business: IBusinessGetProfileById | null = null
  public resource: IResourceGetBusinessResources[number] | null = null
  public resources: IResourceGetBusinessResources = []
  public businessIdByResourceId: { [key: string]: string } = {}
  public businessId = ''
  public fieldsList: IFieldGetFieldsList | null = null
  public fieldGroups: IFieldGetFieldGroups | null = null
  public fieldPresets: IFieldGetFieldPresets | null = null

  constructor() { makeAutoObservable(this); }
}
