import { ru } from './ru_RU'
import { he } from './he_IL'

export type TLang = 'ru' | 'he'
export type TTranslations = typeof ru

export const t: TTranslations = Object.assign({}, ru)

export const setLocale = (locale: string) => Object.assign(t, ({
  ru, he
})[locale.split('-')[0].toLowerCase()]);
