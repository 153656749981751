import React from 'react'
import { ScreenLayout } from '../shared/ui/ScreenLayout'
import { PersonInfo } from '../shared/ui/PersonInfo'
import { Button } from '../shared/ui/Button'
import { store } from '../../store'
import { authService } from '../../services/AuthService'
import { formatClientName, formatClientPhoneNumber } from './ScreenClientCard'
import { t } from '../../config/i18n'
import { useNavigate } from 'react-router'

export const ScreenProfile = () => {
  const navigate = useNavigate()
  const profile = store.profile.profile
  //console.log({ profile });
  if (!profile) return <div></div>

  return (
    <ScreenLayout
      title={t.nav.profile}
      className="bg-white"
      onBack={() => navigate('/')}
    >
      <PersonInfo
        avatarSrc=""
        name={formatClientName(profile.profile)}
        description={profile.profile.id}
      />
      <div className="flex">
        <div className="pl10 pr7 rigid"></div>
        <div className="flex col fill">
          <Button type="solid" color="warning"
            onClick={() => {
              authService.logout(true);
            }}
          > {t.auth.logoutButton} </Button>
        </div>
      </div>
    </ScreenLayout>
  )
}
