import React from 'react'
import { TabBar } from 'antd-mobile'
import styles from './Footer.module.scss'
import { ClockCircleOutline, UserCircleOutline, TeamOutline } from 'antd-mobile-icons'
import { useNavigate, useLocation } from 'react-router'
import { t } from '../../../../config/i18n'
import { store } from '../../../../store'

const routeKeys: { [index: string]: string } = {
  '/': '/appointments',
  '/appointment-info': '/appointments',
  '/client-card': '/clients'
}

interface Props {
  children?: React.ReactNode
  onHeightChange: (h: number) => void
}

export const ScreenLayoutFooter = ({ children, onHeightChange }: Props) => {
  const navigate = useNavigate()
  let activeKey = useLocation().pathname;
  const b_Id = store.profile.business?.business?.id || '';
  activeKey = activeKey in routeKeys ? routeKeys[activeKey] : activeKey
  const showClients = store.profile.profile?.backoffice_operation?.operations.find(i => i.operation_type === 'SCREEN_CLIENTS' && i.businesses.includes(b_Id))

  let tabs = [
    {
      title: t.nav.appointments,
      icon: <ClockCircleOutline />,
      url: '/appointments',
    },
    {
      title: t.nav.clients,
      icon: <TeamOutline />,
      url: '/clients'
    },
    {
      title: t.nav.profile,
      icon: <UserCircleOutline />,
      url: '/profile',
    }
  ]

  if (!showClients) {
    tabs = tabs.filter(i => i.title !== t.nav.clients)
  }
  const [height, setHeight] = React.useState(0)

  const ref = React.useRef() as React.RefObject<HTMLDivElement>;
  React.useLayoutEffect(() => {
    const dom = ref.current;
    if (!dom) return;
    const h = (
      dom.getBoundingClientRect().height +
      dom.children[0].getBoundingClientRect().height
    );
    if (h != height) { setHeight(h); onHeightChange(h); }
  }, [activeKey])

  return (
    <div ref={ref} className={styles.root}>
      <div className={styles.item}> {children} </div>
      <TabBar activeKey={activeKey} onChange={navigate}>
        {tabs.map((item, id) => (
          <TabBar.Item key={item.url} icon={item.icon} title={item.title} />
        ))}
      </TabBar>
    </div>
  )
}
