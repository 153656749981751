import React, { useEffect, useState } from 'react'
import { debounce, localDate } from '../../lib/utilsCommon'
import { useNavigate } from '../../hooks/useNavigate'
import { useLoader } from '../../hooks/useLoader'
import { ScreenLayout } from '../shared/ui/ScreenLayout'
import { SingleDaySelector } from '../shared/ui/SingleDaySelector'
import { CustomSteps, Item } from './screenAppointmentsList/CustomSteps'
import { CalendarOutline } from 'antd-mobile-icons'
import { ProgressBar, Divider, DotLoading, Calendar, Dropdown } from 'antd-mobile'
import { formatAppointmentStartTime } from './ScreenAppointmentInfo'
import { formatClientName } from './ScreenClientCard'
import { appointmentsService } from '../../services/AppointmentsService'
import { profileService } from '../../services/ProfileService'
import { observer } from 'mobx-react'
import { store } from '../../store'
import { useOnMount } from '../../hooks/useOnMount'
import { t } from '../../config/i18n'
import cn from 'classnames'

type IResource = typeof store["profile"]["resource"];
const ResourcePicker = () => {
    const ref = React.useRef<any>(null);
    const containerRef = React.useRef<any>(null);
    
    const [selected, setSelected]: [IResource, any] = [
      store.profile.resource,
      profileService.setResource
    ];
    const list = store.profile.resources;
    const isSelected = (item: IResource) => selected?.id == item?.id;
    const businessName = (id: string) => store.profile.profile?.businessData?.[id]?.name;
    
    const ids = store.profile.businessIdByResourceId;
    const itemName = (item: IResource) => item && `${businessName(ids[item.id])} / ${item?.name || ''} ${item?.surname || ''}`;
    
    const top = !containerRef.current ? 0 : containerRef.current.getBoundingClientRect().top;
    
    return <Dropdown ref={ref}>
      <Dropdown.Item key='0'
        title={itemName(selected)}
      >
        <div className="flex col"
          ref={containerRef}
          style={{maxHeight: `calc(100vh - ${top}px)`, overflow: "auto"}}
        >
          {(list || []).length == 0
            ? <div className="pa3"> { t.inputs.dropdownNoElements } </div>
            : (list || []).map((d: any, i: number) => <div
              key={i}
              onClick={() => {
                ref.current?.close();
                setSelected(d);
              }}
              className={cn("pl3 pr3 pt2 pb2 text-md", isSelected(d) && "font-bold")}
            >
              { itemName(d) }
            </div>)
          }
        </div>
      </Dropdown.Item>
    </Dropdown>
}

export const ScreenAppointmentsList = observer(() => {
  const navigate = useNavigate()
  const [date, setDate] = [
    store.appointments.selectedDate || new Date(),
    (v: Date) => { store.appointments.selectedDate = v; }
  ];
  const [progress, setProgress] = React.useState(5);
  
  const [changeData, setChangeData] = useState<{ from?: Item, to?: Item, readyToLoad: boolean }>({ readyToLoad: false })
  
  const [loading, load] = useLoader((date: Date) => appointmentsService.loadList(
    new Date(new Date(date).setHours(0, 0, 0, 0)),
    new Date(new Date(date).setHours(23, 59, 59, 999))
  ));
  
  const loadAppointments = React.useCallback(debounce(load, 250), []);
  React.useEffect(() => {
    load(date);
  }, [+date.setHours(0, 0, 0, 0), store.profile.resource?.id]);
  
  const [datePickerOpen, setDatePickerOpen] = React.useState(false);
  
  const list = [...(store.appointments.list || [])].sort((a, b) =>
    +new Date(a.appointment.start) - +new Date(b.appointment.start)
  );

  const [swapping, swapDates] = useLoader(appointmentsService.swapDatesAppointments);

  useEffect(() => {
    async function uploadResults() {
      if (changeData.readyToLoad) {
        const firstApp = store.appointments.list.find(item => item.appointment.id === changeData.from!.id)
        const secondApp = store.appointments.list.find(item => item.appointment.id === changeData.to!.id)
        setChangeData({ readyToLoad: false })
        if (!firstApp || !secondApp) {
          return
        }
        
        // TODO тут отправляем даты
        const firstAppData = {
          id: firstApp.appointment.id,
        }
        const secondAppData = {
          id: secondApp.appointment.id,
        }
        await swapDates({ from: firstAppData, to: secondAppData })

        await load(date);
      }
    }

    uploadResults()
  }, [changeData])

  const getFirstDayOfWeek = (locale: string) => {
    const localeFirstDayMap: Record<string, 'Monday' | 'Sunday' | undefined> = {
      'he': "Sunday",
      'ru': "Monday",
    };
    return localeFirstDayMap[locale] ?? "Sunday";
  };

  return <ScreenLayout
    title={t.appointment.list}
    loading={false/*loading*/}
    footer={''/*<div className="pa2">
      <div className="flex between mb2">
        <div>{t.appointment.myProgress}:</div>
        <div>{progress}/10</div>
      </div>
      <ProgressBar percent={progress / 10 * 100} />
    </div>*/}
    actionIcon={<div onClick={() => setDatePickerOpen(true)}>
      <CalendarOutline />
    </div>}
    onAction={() => {}}
  >
    {datePickerOpen
    ? <Calendar
      selectionMode='single'
      defaultValue={new Date()}
      onChange={(d: Date | null) => {
        setDatePickerOpen(false);
        setDate(d || new Date());
      }}
      weekStartsOn={getFirstDayOfWeek(t.antd.locale)}
    />
    : <>
      <SingleDaySelector value={date} onChange={setDate}/>
      <div className="mt2"></div>
      {(store.profile.resources || []).length > 0 && <ResourcePicker/>}
      {loading ? <div className="flex center pa3"> <DotLoading color='primary'/> </div> : <>
        <Divider className="bold">{t.client.list}</Divider>
        <CustomSteps
          current={[
            ...list.map(ap => localDate(ap.appointment.start)),
            new Date(+new Date() + 1000)
          ].findIndex(dt => dt > new Date())}
          onItemClick={(item: any) => navigate('/appointment-info/:id', { id: item.id } as never)}
          onItemDragAndDropChange={(itemFrom: Item, itemTo: Item) => setChangeData({ from: itemFrom, to: itemTo, readyToLoad: true })}
          items={list.map((ap, i: any) => ({
            id: ap.appointment.id,
            title: [
              formatAppointmentStartTime(ap.appointment),
              formatClientName(ap.client)
            ],
            cancelled: [
              'CANCELLED_BY_BUSINESS', 'CANCELLED_BY_CLIENT'
            ].includes(ap.appointment.status),
            description: [
              ap.taxonomy?.alias,
              ap.receptionType?.name
            ].filter(v => v).join(', ')
          }))}
        />
      </>}
    </>}
  </ScreenLayout>
})
