import * as React from 'react'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import { AppRouterHandler } from './shared/AppRouterHandler'
import { ScreenAppointmentsList } from './screens/ScreenAppointmentsList'
import { ScreenAppointmentInfo } from './screens/ScreenAppointmentInfo'
import { ScreenClientCard } from './screens/ScreenClientCard'
import { ScreenClients } from './screens/ScreenClients'
import { ScreenProfile } from './screens/ScreenProfile'
import { ScreenAuth } from './screens/ScreenAuth'

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <AppRouterHandler />
      <Routes>
        <Route path="/" element={<ScreenAppointmentsList />} />
        <Route path="/auth" element={<ScreenAuth />} />
        <Route path="/profile" element={<ScreenProfile />} />
        <Route path="/appointments" element={<ScreenAppointmentsList />} />
        <Route path="/appointment-info/:id" element={<ScreenAppointmentInfo />} />
        <Route path="/client-card/:id" element={<ScreenClientCard />} />
        <Route path="/clients" element={<ScreenClients />} />
        <Route path="*" element={<div></div>} /> {/* if page not found */}
      </Routes>
    </BrowserRouter>
  )
}
