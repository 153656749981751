import React from 'react'
import { useNavigate } from '../../hooks/useNavigate'
import { useLoader } from '../../hooks/useLoader'
import { useParams } from 'react-router'
import { ScreenLayout } from '../shared/ui/ScreenLayout'
import { ListItem } from '../shared/ui/ListItem'
import { Button } from '../shared/ui/Button'
import { AppointmentCancelled } from './screenAppointmentInfo/AppointmentStatus'
import { ExtraFieldGroups } from '../shared/ui/ExtraFieldGroups'
import { Avatar, DotLoading, TextArea, Dialog } from 'antd-mobile'
import {
  CalendarOutline, ClockCircleOutline, ContentOutline, EnvironmentOutline,
  PhoneFill, TextOutline, RightOutline, CloseCircleOutline
} from 'antd-mobile-icons'
import { formatClientName, formatClientPhoneNumber, formatClientAddress } from './ScreenClientCard'
import { appointmentsService, IAppointmentChanges } from '../../services/AppointmentsService'
import { routerService } from '../../services/RouterService'
import { localDate } from '../../lib/utilsCommon'
import { observer } from 'mobx-react'
import { store } from '../../store'
import { useOnMount } from '../../hooks/useOnMount'
import { t } from '../../config/i18n'

export const formatAppointmentStart = (ap: any) =>
  localDate(ap.start).toLocaleDateString(t.locale, {
    month: 'long', weekday: 'long', day: 'numeric'
  });

export const formatAppointmentStartTime = (ap: any) =>
  localDate(ap.start).toLocaleTimeString(t.locale, {
    hour: '2-digit', hourCycle: 'h23', minute: '2-digit'
  });

export const formatAppointmentTimeRange = (ap: any) => [
  localDate(ap.start).toLocaleTimeString(t.locale, {
    hour: '2-digit', hourCycle: 'h23', minute: '2-digit'
  }),
  localDate(+new Date(ap.start) + ap.duration * 60 * 1000).toLocaleTimeString(t.locale, {
    hour: '2-digit', hourCycle: 'h23', minute: '2-digit'
  })
].join(' - ');

export const formatAppointmentDuration = (ap: any) =>
  `${t.appointment.duration}: ${ap.duration} ${t.common.minutesShort}`;

const appointmentChanges = (): IAppointmentChanges => ({
  notes: null,
  status: null,
  extraFields: {}
});

export const ScreenAppointmentInfo = observer(() => {
  const navigate = useNavigate();
  const { id } = useParams();
  
  const [loading, load] = useLoader(async (forceReload: boolean) => {
    if (id) await appointmentsService.loadAppointment(id, forceReload);
  });
  useOnMount(() => load(false));
  
  const appointment = store.appointments.appointment;
  const extraFields = appointment?.extraFields || [];
  const { fieldGroups, fieldPresets, fieldsList } = store.profile;
  
  const [changes, setChanges] = React.useState<IAppointmentChanges>(appointmentChanges());
  const clearChanges = () => setChanges(appointmentChanges());
  React.useEffect(clearChanges, [extraFields.map(f => f.id).join('')]);
  
  const [applying, applyChanges] = useLoader(appointmentsService.updateAppointment);
  const hasChanges = changes.notes != null || Object.entries(changes.extraFields).length > 0;
  
  const CancelBtn = () => <div onClick={() => {
      Dialog.confirm({
        cancelText: t.common.no,
        confirmText: t.common.yes,
        content: t.appointment.cancelQuestion,
        onConfirm: async () => {
          if (id) await appointmentsService.cancelAppointment(id);
          await load(true);
        },
      })
    }}>
      <CloseCircleOutline/>
    </div>;
  
  const SaveBtn = () => <div className="pa3">
    {hasChanges && <Button
      type='solid' color='primary' fill={true}
      onClick={() => applyChanges(changes).finally(clearChanges)}
    >
      {applying ? <DotLoading color='white'/> : t.common.saveChanges}
    </Button>}
  </div>;
  
  const address = appointment && formatClientAddress(appointment.client, store.profile.business?.business);
  const phoneNumber = appointment && formatClientPhoneNumber(appointment.client);
  return <ScreenLayout
    title={t.appointment.info}
    className=""
    loading={loading}
    onBack={() => navigate('/appointments')}
    onAction={() => {}}
    actionIcon={appointment?.appointment?.status != 'CANCELLED_BY_BUSINESS' && <CancelBtn/>}
    footer={appointment?.appointment?.status == 'CANCELLED_BY_BUSINESS' && <AppointmentCancelled/>}
  >
    {!appointment ? <></> : <>
      <ListItem
        icon={<Avatar src='' style={{ '--size': '20px' }}/>}
        rightIcon={<div className="flex center">{t.common.info}<RightOutline/></div>}
        onClick={() => navigate('/client-card/:id', { id: appointment.client.id } as never)}
        title={formatClientName(appointment.client)}
        description={appointment.client.shortId}
      />
      <ListItem
        icon={<CalendarOutline/>}
        title={formatAppointmentStart(appointment.appointment)}
        description=""
      />
      <ListItem
        icon={<ClockCircleOutline/>}
        title={formatAppointmentTimeRange(appointment.appointment)}
        description={formatAppointmentDuration(appointment.appointment)}
      />
      <ListItem
        icon={<ContentOutline/>}
        title={[
          appointment.taxonomy?.alias, appointment.receptionType?.name
        ].filter(v => v).join(', ')}
        description=""
      />
      <ListItem
        icon={<EnvironmentOutline/>}
        title={
          address
          ? <a href={`//google.com/maps/place/${encodeURIComponent(address)}`}> {address} </a>
          : <div className="color-gray">{t.appointment.noAddress}</div>
        }
        highlight={true}
        description=""
      />
      <ListItem
        icon={<PhoneFill/>}
        title={<a href={`tel:${phoneNumber}`}> {phoneNumber} </a>}
        highlight={true}
        description=""
      />
      <ListItem
        icon={<TextOutline/>}
        title={""}
        description={<TextArea
          value={(changes.notes != null ? changes.notes : appointment.notes) || ''}
          autoSize={true}
          onChange={v => setChanges({
            ...changes,
            notes: v
          })}
        />}
      />
      <ExtraFieldGroups
        extraFieldGroups={(fieldGroups || [])}
        extraFields={extraFields
          .map((f: any) => ({
            id: f.fieldID,
            name: f.fieldName,
            value: f.value,
            metadata: (fieldsList || []).map((d: any) => d.field).find((d: any) => d?.id == f.fieldID)
          }))
          .filter(d => d.metadata)}
        changes={changes.extraFields}
        onChange={extraFieldsChanges => setChanges({
          ...changes,
          extraFields: extraFieldsChanges
        })}
      />
      <SaveBtn/>
    </>}
  </ScreenLayout>
})
