const locale = 'he';

const antd = {
  locale,
  common: {
    confirm: 'אישור',
    cancel: 'ביטול',
    loading: 'טעינה'
  },
  Calendar: {
    markItems: ['ב', 'ג', 'ד', 'ה', 'ו', 'ש', 'א'],
    renderYearAndMonth: (year: any, month: any) => `${year}/${month}`
  },
  Cascader: {
    placeholder: 'בחירה'
  },
  Dialog: {
    ok: 'OK'
  },
  ErrorBlock: {
    default: {
      title: 'משהו השתבש',
      description: ''
    },
    busy: {
      title: 'טעינת הנתונים נכשלה',
      description: ''
    },
    disconnected: {
      title: '',
      description: ''
    },
    empty: {
      title: '',
      description: ''
    }
  },
  Form: {
    required: 'חובה',
    optional: 'בחירה',
    defaultValidateMessages: {
      default: 'נתונים שגיויים בשדה ${label}',
      required: 'שדה חובה ${label}',
      enum: 'ערכים תקפים עבור ${label}: ${enum}',
      whitespace: '${label} שדה חובה',
      date: {
        format: '',//'${label} date format is invalid',
        parse: '',//'${label} cannot be converted to a date',
        invalid: '',//'${label} is an invalid date'
      },
      types: {
        string: '',//'${label} is not a valid ${type}',
        method: '',//'${label} is not a valid ${type}',
        array: '',//'${label} is not a valid ${type}',
        object: '',//'${label} is not a valid ${type}',
        number: '',//'${label} is not a valid ${type}',
        date: '',//'${label} is not a valid ${type}',
        boolean: '',//'${label} is not a valid ${type}',
        integer: '',//'${label} is not a valid ${type}',
        float: '',//'${label} is not a valid ${type}',
        regexp: '',//'${label} is not a valid ${type}',
        email: '',//'${label} is not a valid ${type}',
        url: '',//'${label} is not a valid ${type}',
        hex: '',//'${label} is not a valid ${type}'
      },
      string: {
        len: 'לכל היותר ${label}: ${len} תווים',
        min: 'לכל היותר ${label}: מ ${len} תווים',
        max: 'לכל היותר ${label}: עד ${len} תווים',
        range: 'לכל היותר ${label}: מ ${len} עד ${max} תווים'
      },
      number: {
        len: '',//'${label} must be equal to ${len}',
        min: '',//'${label} must be minimum ${min}',
        max: '',//'${label} must be maximum ${max}',
        range: '',//'${label} must be between ${min}-${max}'
      },
      array: {
        len: '',//'Must be ${len} ${label}',
        min: '',//'At least ${min} ${label}',
        max: '',//'At most ${max} ${label}',
        range: '',//'The amount of ${label} must be between ${min}-${max}'
      },
      pattern: {
        mismatch: '',//'${label} does not match the pattern ${pattern}'
      }
    }
  },
  ImageUploader: {
    uploading: 'מעדכן...',
    upload: 'העלאה'
  },
  InfiniteScroll: {
    noMore: '',//'No more',
    failedToLoad: 'טעינה נכשלה',
    retry: 'נסה שוב'
  },
  Mask: {
    name: '',//'Mask'
  },
  Modal: {
    ok: 'OK'
  },
  PullToRefresh: {
    pulling: '',//'Scroll down to refresh',
    canRelease: '',//'Release to refresh immediately',
    complete: '',//'Refresh successful'
  },
  Slider: {
    name: '',//'Slider'
  },
  Stepper: {
    decrease: 'להגדיל',
    increase: 'להקטין'
  },
  Switch: {
    name: '',//'Switch'
  }
};


export const he = {
  antd,
  locale,
  common: {
    download: 'הורד',
    cancel: 'ביטול',
    confirm: 'אישור',
    yes: 'כן',
    no: 'לא',
    general: 'כללי',
    search: 'חיפוש',
    sex: 'מין',
    sexName: {
      MALE: 'זכר',
      FEMALE: 'נקבה',
      NOT_SPECIFIED: 'לא צויין'
    },
    minutesShort: 'ד.',
    ehr: 'כרטיס מטופל',
    birthday: 'תאריך לידה',
    phoneNumber: 'טלפון',
    email: 'ד. אלקטרוני',
    appointmentInfo: 'לגבי הביקור',
    info: 'מידע',
    mainInfo: 'מידע בסיסי',
    additionalInfo: 'מידע נוסף',
    saveChanges: 'שמור שינויים'
  },
  auth: {
    title: 'Doctor Cabinet',
    formTitle: 'הרשאה',
    login: 'התחברות',
    password: 'סיסמה',
    loginButton: 'כניסה',
    logoutButton: 'יציאה'
  },
  nav: {
    profile: 'הפרופיל',
    clients: 'מטופלים',
    appointments: 'ביקורים',
  },
  appointment: {
    myProgress: 'ההתקדמות שלי',
    comment: 'משוב על הקבלה',
    noNotes: 'הערה לא צוינה',
    noAddress: 'לא צויין כתובת',
    list: 'תורים',
    info: 'הקלטת מידע',
    cancelQuestion: 'לבטל את התור?',
    cancelled: 'בוטל',
    completed: 'הושלם',
    duration: 'משך'
  },
  client: {
    info: 'מידע על המטופל',
    list: 'מטופלים',
    id: 'זיהוי מטופל',
    noBirthday: 'ת. לידה חסר',
    noEmail: 'ד.אלקטרוני חסר',
    noPhone: 'מס טלפון חסר'
  },
  dates: {
    months: [
      'ינואר', 'פברואר', 'מרץ', 'אפריל', 'מאי', 'יוני',
      'יולי', 'אוגוסט', 'ספטמבר', 'אוקטובר', 'נובמבר', 'דצמבר'
    ],
    weekdays: [
      'שני', 'שלישי', 'רביעי', 'חמישי',
      'שישי', 'שבת', 'ראשון'
    ],
    weekdaysShort: ['ב', 'ג', 'ד', 'ה', 'ו', 'ש', 'א']
  },
  inputs: {
    textPlaceholder: 'הזן מלל',
    datePlaceholder: 'בחר תאריך',
    filePlaceholder: 'אין קובץ',
    dropdownPlaceholder: 'לא נבחר',
    dropdownNoElements: 'אין תוצאות',
  },
  customStep: {
    textChangeSlot: 'האם אתה בטוח שברצונך לשנות את משבצות הזמן האלה?',
    confirm: 'אישור',
    cancel: 'ביטול',
  },
}
