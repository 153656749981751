import { UIStore } from './UIStore'
import { ProfileStore } from './ProfileStore'
import { AppointmentsStore } from './AppointmentsStore'
import { ClientsStore } from './ClientsStore'

export const store = {
  ui: new UIStore(),
  profile: new ProfileStore(),
  appointments: new AppointmentsStore(),
  clients: new ClientsStore()
}
