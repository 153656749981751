import styles from './ClientInfo.module.scss'
import { useLoader } from '../../../hooks/useLoader'
import { IExtraField } from '../../shared/ui/ExtraField'
import { ExtraFieldGroups, IExtraFieldGroup } from '../../shared/ui/ExtraFieldGroups'
import { InfoItem } from '../../shared/ui/InfoItem'
import { IClientChanges } from '../../../services/ClientsService'
import { Button } from '../../shared/ui/Button'
import { Avatar, Divider, DotLoading } from 'antd-mobile'
import React from 'react'
import cn from 'classnames'
import { t } from '../../../config/i18n'

const clientChanges = (): IClientChanges => ({})

interface Props {
  birthday: string
  gender: string
  phoneNumber: string
  email: string
  extraFieldGroups: IExtraFieldGroup[],
  extraFields: IExtraField[]
  onChange: (changes: IClientChanges) => Promise<any>
}

export const ClientInfo = ({
  birthday, gender, phoneNumber, email,
  extraFieldGroups, extraFields, onChange
}: Props) => {
  const [changes, setChanges] = React.useState<IClientChanges>(clientChanges());
  const clearChanges = () => setChanges(clientChanges());
  React.useEffect(clearChanges, [extraFields.map(f => f.id).join('')]);
  
  const [loading, applyChanges] = useLoader(() => onChange(changes));
  const hasChanges = Object.entries(changes).length > 0;
  
  const SaveBtn = () => <div className="pa3">
    {hasChanges && <Button
      type='solid' color='primary' fill={true}
      onClick={() => applyChanges().finally(clearChanges)}
    >
      {loading ? <DotLoading color='white'/> : t.common.saveChanges}
    </Button>}
  </div>;
  
  return <div className={styles.root}>
    <Divider contentPosition='left'> {t.common.mainInfo} </Divider>
    <div className="flex col pl3 pr3">
      <InfoItem label={t.common.birthday} value={birthday}/>
      <InfoItem label={t.common.sex} value={gender}/>
      <InfoItem label={t.common.phoneNumber}
        value={<a href={`tel:${phoneNumber}`}>{phoneNumber}</a>}
      />
      <InfoItem label={t.common.email} value={email}/>
    </div>
    <Divider contentPosition='left'> {t.common.additionalInfo } </Divider>
    <ExtraFieldGroups
      extraFieldGroups={extraFieldGroups}
      extraFields={extraFields}
      changes={changes}
      onChange={setChanges}
    />
    <SaveBtn/>
  </div>
}
