import './lib/Logger'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { bootstrap } from './Bootstrap'
import { App } from './components/App'
import * as serviceWorker from './serviceWorker'

const root = document.getElementById('root') as HTMLElement

bootstrap
  .init()
  .then(() => {
    const container = document.getElementById('root')
    const root = createRoot(container!)
    console.log(process.env)
    root.render(<App />)
  })
  .catch(window.logger.error)

serviceWorker.unregister()
