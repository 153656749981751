import { rpc } from '../lib/Rpc'
import { store } from '../store'
import { localDateString } from '../lib/utilsCommon'
import { loggerNamespace } from '../lib/Logger'
import { IProfileGetBusiness } from '../interfaces/rpc/IProfileGetBusiness'
import { IProfileGetProfile } from '../interfaces/rpc/IProfileGetProfile'
import { IBusinessGetProfileById } from '../interfaces/rpc/IBusinessGetProfileById'
import { IGetAppointmentByFilterV2 } from '../interfaces/rpc/IGetAppointmentByFilterV2'
import { IAppointmentGetAppointment } from '../interfaces/rpc/IAppointmentGetAppointment'
import { IAppointmentUpdateAppointment } from '../interfaces/rpc/IAppointmentUpdateAppointment'
import { IAppointmentSwapDates } from '../interfaces/rpc/IAppointmentSwapDates'

export interface IAppointmentChanges {
  extraFields: {
    [key: string]: any
  }
  notes: string | null
  status: string | null
}

export interface IAppointmentDateChange {
  id: string,
}

export interface IAppointmentsDatesChanges {
  from: IAppointmentDateChange,
  to: IAppointmentDateChange,
}

class AppointmentsService {
  private logger = loggerNamespace('AppointmentsService')
  
  public async loadList(startDate: Date, endDate: Date) {
    const profile = store.profile.profile?.profile;
    const business = store.profile.business?.business;
    const worker = store.profile.resource;
    if (!business || !profile || !worker) {
      this.logger.error('worker not found');
      return;
    }
    const { error, result } = await rpc.call<IGetAppointmentByFilterV2>('appointment.get_appointment_by_filter_v2', {
      "business": { id: business.id },
      "pageSize": 10000,
      "page": 1,
      "skipBusinessCancelled": true,
      "filter": {
        "workers": [worker?.id],
        "status": ["CONFIRMED_BY_CLIENT", "CONFIRMED_BY_BUSINESS", "CANCELLED_BY_BUSINESS", "CANCELLED_BY_CLIENT"],
        "services": [],
        "rooms": [],
        "start": localDateString(startDate),
        "end": localDateString(endDate),
        "skipClientNotAppear": true,
        "bound_appointments": true
      },
      "extraFilters": {
        "sort": []
      },
      "fields": null,
      "network": {"id": 0}
    });
    this.logger.info('appointment.get_appointment_by_filter_v2', { error, result })
    if (result) {
      store.appointments.list = result.data;
    }
  }
  
  public async loadAppointment(id: string, forceReload: boolean) {    
    const appointment = store.appointments.list.find(d => d.appointment.id == id);
    if (appointment && !forceReload) {
      store.appointments.appointment = appointment;
      return;
    }
    const { error, result } = await rpc.call<IAppointmentGetAppointment>('appointment.get_appointment', {
      appointment: { id }
    })
    
    this.logger.info('appointment.get_appointment', { error, result })
    if (result) {
      store.appointments.appointment = result;
    }
  }
  
  public updateAppointment = async ({ extraFields, notes, status }: IAppointmentChanges) => {
    if (!store.appointments.appointment) return;
    const appointment = JSON.parse(JSON.stringify(store.appointments.appointment)); // deepcopy
    for (const [id, value] of Object.entries(extraFields)) {
      const field = appointment.extraFields.find((f: any) => f.fieldID == id);
      field.value = value;
    }
    appointment.notes = notes;
    appointment.appointment.status = status;
    
    const { error, result } = await rpc.call<IAppointmentUpdateAppointment>('appointment.update_appointment',
      appointment
    );

    this.logger.info('appointment.update_appointment', { appointment, error, result })
    if (result) {
      store.appointments.appointment = appointment;
    }
  }

  public swapDatesAppointments = async (data: IAppointmentsDatesChanges) => {
    if (!store.appointments) return;
    const appointments = store.appointments.list.filter(item => item.appointment.id === data.from.id || item.appointment.id === data.to.id);
    if (appointments.length !== 2) return;

    const copyAppointments = appointments.map(item => JSON.parse(JSON.stringify(item))); // deepcopy
    const { error, result } = await rpc.call<IAppointmentSwapDates>('appointment.swap_dates_appointments',
      {
        from: copyAppointments[0],
        to: copyAppointments[1],
      },
    );

    this.logger.info('appointment.swap_dates_appointments', { error, result })
    return { error, result };
  }
  
  public cancelAppointment = async (id: string) => {
    const { error, result } = await rpc.call<IAppointmentGetAppointment>('appointment.cancel_appointment_by_business', {
      appointment: { id }
    })
    
    this.logger.info('appointment.cancel_appointment_by_business', { error, result })
  }
}

const appointmentsService = new AppointmentsService();
export { appointmentsService }
