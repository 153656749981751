/**
 * Application bootstrap
 * fetch initial state, verify tokens etc
 */
import { rpc } from './lib/Rpc'
import { configure } from 'mobx'
import { config } from './config/config'
import { authService } from './services/AuthService'
import { ERROR_ACCESS_DENIED } from './config/consts'
import { debugRootStore } from './lib/utilsCommon'
import { profileService } from './services/ProfileService'

class Bootstrap {

  public async init(): Promise<void> {
    configure({ enforceActions: 'never' })

    if (!await authService.auth()) {
      throw new Error(ERROR_ACCESS_DENIED)
    }
    await profileService.loadProfile()
  }
}

// @ts-ignore
window.debug = () => ({
  rpc,
  config,
  rootStore: debugRootStore(),
  authData: authService.authData,
})

const bootstrap = new Bootstrap()
export { bootstrap }
