import React from 'react'
import { LeftOutline, RightOutline } from 'antd-mobile-icons'
import styles from './SingleDaySelector.module.scss'
import { t } from '../../../config/i18n'

interface Props {
  value?: Date
  onChange?: (value: Date) => void
}

export const SingleDaySelector = ({ value=new Date(), onChange=()=>{} }: Props) => {
  const month = value.getMonth();
  const day = value.getDate();
  const weekday = (value.getDay() + 7 - 1) % 7;
  
  const dec = () => onChange(new Date(value.setDate(day - 1)));
  const inc = () => onChange(new Date(value.setDate(day + 1)));
  
  return <div className={styles.root}>
    <LeftOutline fontSize={19} className="rigid" onClick={dec} />
    <div className="flex col">
      <div className={styles.month}> {t.dates.months[month]} </div>
      <div className={styles.day}> {day} </div>
      <div className={styles.weekday}> {t.dates.weekdays[weekday]} </div>
    </div>
    <RightOutline fontSize={19} className="rigid" onClick={inc} />
  </div>
}
