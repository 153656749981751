import styles from './InfoItem.module.scss'
import React from 'react'

interface Props {
  label?: string | null,
  value: string | React.ReactNode
}

export const InfoItem = ({ label, value }: Props) => <div className={styles.root}>
  <div className={styles.label}> {label} </div>
  <div className={styles.value}> {value} </div>
</div>;
