import { makeAutoObservable } from 'mobx'
import { IClientGetClient } from '../interfaces/rpc/IClientGetClient'
import { IAppointmentGetAppointment } from '../interfaces/rpc/IAppointmentGetAppointment'
import { IClientGetBusinessClients } from '../interfaces/rpc/IClientGetBusinessClients'

export class ClientsStore {
  public list: IClientGetBusinessClients | null = null
  public client: IClientGetClient | null = null
  public appointment: IAppointmentGetAppointment | null = null
  public searchValue: string | null = null

  constructor() { makeAutoObservable(this) }
}
