import qs from 'qs'
import { storageService } from './StorageService'
import { loggerNamespace } from '../lib/Logger'
import { config } from '../config/config'
import { rpc } from '../lib/Rpc'

const TOKEN_TTL = 3600000

class AuthService {
  public authData: IAuthData = this.initAuthData({})
  private query: Record<any, any> = qs.parse(document.location.search, { ignoreQueryPrefix: true })
  private logger = loggerNamespace('AuthService')

  public async auth(): Promise<boolean> {
    this.logger.info('auth')
    this.loadAuthData()
    rpc.user = this.authData.userInfo?.user_id || ''
    rpc.token = this.authData.token || ''

    if (this.query.code) {
      const authData = await this.authByCode(this.query.code)
      if (this.validateAuthData(authData)) {
        const userInfo = await this.getUserData(authData)
        if (authData.userInfo) {
          authData.userInfo.su = userInfo.su
          authData.userInfo.root = userInfo.root
        }
        this.authData = authData
        rpc.user = this.authData.userInfo?.user_id || ''
        rpc.token = this.authData.token || ''
        this.storeAuthData(this.authData)
        this.logger.info('authByCode success')
      }
      document.location.replace('/')
      return true
    }
    
    if (!this.validateAuthData(this.authData, true)) {
      this.redirectToAuthServer()
      return false
    }

    if (this.authData.expire < Date.now()) {
      const authData = await this.refreshToken(this.authData)
      if (this.validateAuthData(authData, true)) {
        this.authData = authData
        rpc.token = this.authData.token
        this.storeAuthData(authData)
        this.logger.info('refreshToken success')
        return true

      } else {
        this.authData = this.initAuthData()
        this.redirectToAuthServer()
        return false
      }
    }

    return true
  }

  public logout(redirect = false) {
    const { token, userInfo } = this.authData;
    if (token && userInfo?.user_id) {
      this.authRequest('/logout', {
        token: token,
        userid: userInfo.user_id,
        client_id: config.domain.CLIENT_ID,
      })
    }
    this.authData = this.initAuthData()
    this.storeAuthData(this.authData)
    if (redirect) {
      document.location.href = `${config.domain.OAUTH_SERVER}/login?client_id=${config.domain.CLIENT_ID}`;
    }
  }

  private async authByCode(code: string): Promise<IAuthData> {
    this.logger.info('authByCode')
    const { error, refreshToken, token, user } = await this.authRequest('/exchange', {
      code,
      client_id: config.domain.CLIENT_ID,
      redirect_uri: config.domain.REDIRECT_URI,
    })

    if (token && refreshToken && user) {
      return this.initAuthData({
        token,
        refresh: refreshToken,
        expire: Date.now() + TOKEN_TTL,
        userInfo: {
          user_id: user,
          root: false,
          su: false,
        }
      })

    } else if (!error) {
      this.logger.error('Unknown error')
    }

    this.logout()
    return this.authData
  }

  private async refreshToken(authData: IAuthData): Promise<IAuthData> {
    this.logger.info('refreshToken')
    const { error, access_token, refresh_token } = await this.authRequest('/refresh', {
      token: authData.token,
      refresh: authData.refresh,
    })

    if (access_token && refresh_token) {
      return {
        ...authData,
        token: access_token,
        refresh: refresh_token,
        expire: Date.now() + TOKEN_TTL,
      }

    } else if (!error) {
      this.logger.error('Unknown error')
    }

    this.logout()
    return this.authData
  }

  private async getUserData(authData: IAuthData): Promise<IAuthDataUserInfo> {
    return await this.authRequest(
      `/authorize/info?token=${authData.token}`,
      {},
      'GET') as IAuthDataUserInfo
  }

  private redirectToAuthServer() {
    this.logger.info('redirectToAuthServer')
    document.location.href = `${config.domain.OAUTH_SERVER}/authorize?type=web_server&redirect_uri=${config.domain.REDIRECT_URI}&response_type=code&client_id=${config.domain.CLIENT_ID}`
  }

  private loadAuthData() {
    const authData = storageService.get('authData', {})
    this.authData = this.initAuthData(authData)
  }

  private storeAuthData(authData: IAuthData) {
    storageService.set('authData', authData)
  }

  private initAuthData(data: Partial<IAuthData> = {}): IAuthData {
    return {
      expire: data.expire || 0,
      token: data.token || '',
      refresh: data.refresh || '',
      userInfo: Object.assign({
        user_id: '',
        email: '',
        root: false,
        su: false,
      }, data.userInfo || {}),
    }
  }

  private validateAuthData(authData: IAuthData, withUser = false): boolean {
    return withUser
      ? !!(authData.token && authData.refresh && authData.userInfo?.user_id)
      : !!(authData.token && authData.refresh)
  }

  private async authRequest(path: string, payload: Record<any, any> = {}, method = 'POST'): Promise<Record<any, any>> {
    try {
      const response = await (await fetch(`${config.domain.OAUTH_SERVER}${path}`, {
        method,
        headers: { 'Content-Type': 'application/json' },
        body: method === 'POST' ? JSON.stringify(payload) : undefined,
      })).json()
      if (response.error) {
        this.logger.error(response.error)
        return { error: response.error }
      }
      return response

    } catch (e: Error | any) {
      this.logger.error(e)
      return { error: e.message ?? e.toString() }
    }
  }
}

interface IAuthDataUserInfo {
  user_id: string
  root: boolean
  su: boolean
}

interface IAuthData {
  expire: number
  token: string
  refresh: string
  userInfo?: IAuthDataUserInfo
}

const authService = new AuthService()
export { authService }
