import styles from './PersonInfo.module.scss'
import { Avatar, Tabs, Badge, Divider, TextArea } from 'antd-mobile'
import React from 'react'
import cn from 'classnames'

interface Props {
  name: string
  description?: string
  avatarSrc: string
}

export const PersonInfo = ({ name, description, avatarSrc='' }: Props) => <div className={styles.root}>
  <div className={styles.info}>
    <Avatar src={avatarSrc}/>
    <div className="flex col">
      <div className={styles.name}>{name}</div>
      <div className={styles.description}>{description}</div>
    </div>
  </div>
</div>
