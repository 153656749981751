import * as React from 'react'
import { useLocation, useNavigate } from 'react-router'
import { routerService } from '../../services/RouterService'

export const AppRouterHandler = () => {
  const navigate = useNavigate()
  const location = useLocation()
  React.useEffect(() => {
    routerService.navigate = navigate
    routerService.location = location
  }, [navigate, location])
  return null
}
