import { makeAutoObservable } from 'mobx'
import { IAppointmentGetAppointment } from '../interfaces/rpc/IAppointmentGetAppointment'

export class AppointmentsStore {
  public list: IAppointmentGetAppointment[] = []
  public appointment: IAppointmentGetAppointment | null = null
  public selectedDate: Date | null = null

  constructor() { makeAutoObservable(this) }
}
