import { rpc } from '../lib/Rpc'
import { store } from '../store'
import { loggerNamespace } from '../lib/Logger'
import { IClientGetClient } from '../interfaces/rpc/IClientGetClient'
import { IClientGetBusinessClients } from '../interfaces/rpc/IClientGetBusinessClients'
import { IProfileGetProfile } from '../interfaces/rpc/IProfileGetProfile'
import { IAppointmentGetAppointment } from '../interfaces/rpc/IAppointmentGetAppointment'
import { IClientUpdateClient } from '../interfaces/rpc/IClientUpdateClient'

export interface IClientChanges {
  [id: string]: any
}

class ClientsService {
  private logger = loggerNamespace('ClientsService')
  
  public async loadList() {
    const business = store.profile.business?.business;
    if (!business) return;
    
    const { error, result } = await rpc.call<IClientGetBusinessClients>('client.get_business_clients', {
      "business": { id: business.id },
      "pageSize": 10000,
      "page": 1
    });
    this.logger.info('client.get_business_clients', { error, result })
    if (result) {
      store.clients.list = result;
    }
  }
  
  public async loadClient(id: string) {
    const { error, result } = await rpc.call<IClientGetClient>('client.get_client', {
      "client": { id }
    });
    this.logger.info('client.get_client', { error, result });
    if (result) {
      store.clients.client = result;
      const appointment = result.client.lastCreatedAppointment;
      if (appointment) {
        await this.loadAppointment(appointment.appointmentID);
      }
    }
  }
  
  public async loadAppointment(id: string) {
    const appointment = store.appointments.list.find(d => d.appointment.id == id);
    if (appointment) {
      store.clients.appointment = appointment;
      return;
    }
    const { error, result } = await rpc.call<IAppointmentGetAppointment>('appointment.get_appointment', {
      appointment: { id }
    })
    this.logger.info('appointment.get_appointment', { error, result })
    if (result) {
      store.clients.appointment = result;
    }
  }
  
  public updateExtraFields = async (changes: IClientChanges) => {
    if (!store.clients.client || !store.profile.businessId) return;
    const client = JSON.parse(JSON.stringify(store.clients.client)); // deepcopy
    for (const [id, value] of Object.entries(changes)) {
      const field = client.client.extraFields.find((f: any) => f.fieldID == id);
      field.value = value;
      console.log({id,value})
    }
    
    const { error, result } = await rpc.call<IClientUpdateClient>('client.update_client', {
      business: { id: store.profile.businessId },
      client: client.client
    })

    this.logger.info('client.update_client', { client, error, result })
    if (result) {
      store.clients.client = client;
    }
  }
}

const clientsService = new ClientsService();
export { clientsService }
