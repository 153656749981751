import React from 'react'
import { observer } from 'mobx-react'
import { DotLoading } from 'antd-mobile'
import { useLocation, useNavigate } from 'react-router'
import { ScreenLayoutHeader } from './screenLayout/Header'
import { ScreenLayoutFooter } from './screenLayout/Footer'
import styles from './ScreenLayout.module.scss'
import cn from 'classnames'

export interface Props {
  loading?: boolean
  title: string | null
  actionIcon?: React.ReactNode
  onAction?: () => void
  onBack?: () => void
  children: React.ReactNode
  footer?: React.ReactNode | null
  className?: string
}

const HEADER_HEIGHT = 45;

export const ScreenLayout = observer(({
  loading, title, actionIcon, onAction, onBack, children,
  footer, className='pa3'
}: Props) => {
  const location = useLocation()
  const navigate = useNavigate()
  const ref = React.useRef() as React.RefObject<HTMLDivElement>
  
  const headerHeight = title != null ? HEADER_HEIGHT : 0
  const [footerHeight, setFooterHeight] = React.useState(0)

  return (
    <div ref={ref} className={styles.root}
      style={{
        paddingTop: headerHeight + 'px',
        paddingBottom: footerHeight + 'px'
      }}
    >
      {title != null && <ScreenLayoutHeader
        title={title}
        actionIcon={actionIcon}
        onAction={onAction}
        onBack={onBack}
      />}
      <div className={className}
        style={{
          minHeight: `calc(100vh - ${footerHeight + headerHeight}px)`
        }}>
        {loading
          ? <div className="flex center pa6"> <DotLoading color='primary'/> </div>
          : children}
      </div>
      {footer !== null && <ScreenLayoutFooter onHeightChange={setFooterHeight}>
        {footer}
      </ScreenLayoutFooter>}
    </div>
  )
})
