import React from 'react'
import { useNavigate } from 'react-router'
import { ScreenLayout } from '../shared/ui/ScreenLayout'
import { PersonInfo } from '../shared/ui/PersonInfo'
import { Button } from '../shared/ui/Button'
import { t } from '../../config/i18n'
import { Input } from 'antd-mobile'

export const ScreenAuth = () => {
  const navigate = useNavigate()

  return (
    <ScreenLayout
      className="bg-white pa8"
      title={null}
      footer={null}
    >
      <div className="flex col center">
        <div className="flex col" style={{ width: '150px', height: '46px' }}>
          <img className="fill" src={`${process.env.PUBLIC_URL}/logo.png`}/>
        </div>
        <div className="text-lg pa3">{t.auth.title}</div>
      </div>
      <div className="pt10 pb6 rigid"></div>
      <div className="flex col">
        <div className="mb3 flex center color-gray text-md">{t.auth.formTitle}</div>
        <div className="mb3 border rounded">
          <Input className="pl3 pr3 fill" placeholder={t.auth.login}/>
        </div>
        <div className="mb3 pl3 pr3 border rounded">
          <Input placeholder={t.auth.password} type='password'/>
        </div>
        <Button type="solid" color="primary" fill={true}
          onClick={() => navigate('/')}
        > {t.auth.loginButton} </Button>
      </div>
    </ScreenLayout>
  )
}
