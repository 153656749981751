import styles from './ExtraField.module.scss'
import { Input, Checkbox, Dropdown, TextArea, Button, DatePicker } from 'antd-mobile'
import { CheckOutline, LockOutline, DownlandOutline } from 'antd-mobile-icons'
import React from 'react'
import cn from 'classnames'
import { t } from '../../../config/i18n'
import { config } from '../../../config/config'
import { rpc } from '../../../lib/Rpc'

export const hideField: string[] = [];
export const hideFieldLabel = ['CHECKBOX'];

export interface IExtraField {
  id: string;
  name: string;
  value: any;
  metadata: any;
}

interface Props {
  value: any;
  metadata: any;
  onChange: (val: any) => void;
}

interface CheckboxProps extends Props {
  value: boolean;
  onChange: (val: boolean) => void;
}

interface DropdownItem {
  name: string;
  value: string;
}

interface DropdownProps extends Props {
  value: DropdownItem[] | string;
  onChange: (val: DropdownItem[] | string) => void;
  metadata: {
    dropDownItems: DropdownItem[];
    [key: string]: unknown;
  }
}

// 'TEXT CHECKBOX FILE DROP_DOWN_LIST TEXTAREA DATE_PICKER DATE_TIME_PICKER'

const Inputs = {
  FILE: ({ value, onChange, metadata } : Props) => {
    const href = `${config.domain.API_SERVER}/get_client_document?` + new URLSearchParams({
      gbookingToken: rpc.token,
      gbookingUserId: rpc.user,
      document_id: value
    });
    return (
      !value
      ? t.inputs.filePlaceholder
      : <a className="flex center" href={href}>
        { t.common.download }
        <DownlandOutline style={{marginLeft: '4px'}} fontSize={16}/>
      </a>
    )
  },
    
  DATE_TIME_PICKER: ({ value, onChange, metadata }: Props) => {
    const [visible, setVisible] = React.useState(false);
    return <>
      <Button
        onClick={() => { setVisible(true) }}
      >
        <DatePicker
          visible={visible}
          value={new Date(value)}
          onConfirm={onChange}
          precision='minute'
          onClose={() => { setVisible(false) }}
        >
          {() => !value ? t.inputs.datePlaceholder :
            new Date(value).toLocaleDateString(t.locale, { minute: '2-digit', hour: '2-digit' })
          }
        </DatePicker>
      </Button>
    </>
  },
  
  DATE_PICKER: ({ value, onChange, metadata }: Props) => {
    const [visible, setVisible] = React.useState(false);
    return <>
      <Button
        onClick={() => { setVisible(true) }}
      >
        <DatePicker
          visible={visible}
          value={new Date(value)}
          onConfirm={onChange}
          onClose={() => { setVisible(false) }}
        >
          {() => !value ? t.inputs.datePlaceholder : 
            new Date(value).toLocaleDateString(t.locale)}
        </DatePicker>
      </Button>
    </>
  },
    
  TEXT: ({ value, onChange, metadata } : Props) => 
    <Input placeholder={t.inputs.textPlaceholder} value={value} onChange={onChange}/>,
    
  TEXTAREA: ({ value, onChange, metadata } : Props) => 
    <TextArea placeholder={t.inputs.textPlaceholder} autoSize={true} value={value} onChange={onChange}/>,
    
  CHECKBOX: ({ value, onChange, metadata } : CheckboxProps) => 
    <Checkbox checked={value} onChange={onChange}> { metadata.name } </Checkbox>,
  
  DROP_DOWN_LIST: ({ value, onChange, metadata } : DropdownProps) => {
    const multi = metadata.multiSelect && Array.isArray(value);
    const isSelected = (d: DropdownItem) => (
      multi
      ? !!value.find((d1: DropdownItem) => d.value == d1.value)
      : d.value == value
    );
    const ref = React.useRef<any>(null);
    
    return <Dropdown ref={ref}>
      <Dropdown.Item key='0'
        title={
          [
            multi
            ? value.map((d: DropdownItem) => d?.name).filter(v => v)
            : [value].filter(v => v)
          ]
          .map(v =>
            v.length == 0
            ? t.inputs.dropdownPlaceholder
            : v.sort().join(', ')
          )[0]
        }
      >
        <div className="flex col">
          {(metadata.dropDownItems || []).length == 0
            ? <div className="pa3"> { t.inputs.dropdownNoElements } </div>
            : (metadata.dropDownItems || []).map((d: DropdownItem, i: number) => <div
              key={i}
              onClick={() => {
                if (!metadata.multiSelect) ref.current?.close();
                onChange(
                  multi
                  ? (
                    isSelected(d)
                    ? value.filter((d1: DropdownItem) => d1.value != d.value)
                    : value.concat([d])
                  )
                  : d.value
                );
              }}
              className="pl3 pr3 pt2 pb2 text-md"
            >
              { d.name }
              { isSelected(d) && <CheckOutline className="ml3"/> }
            </div>)
          }
        </div>
      </Dropdown.Item>
    </Dropdown>
  }
} as { [key: string]: any }

export const ExtraField = ({ metadata, value, onChange }: Props) => {
  const Input = Inputs[metadata.fieldType];
  return Input && <div className={cn(styles.root, metadata.readOnly && styles.readonly)}>
    <Input value={value} onChange={onChange} metadata={metadata}/>
    {metadata.readOnly && <LockOutline style={{marginLeft: '8px'}} fontSize={24}/>}
  </div>
}
