import { toJS } from 'mobx'
import { store } from '../store'

export const localDate = (date: Date | string | number) =>
  new Date(+new Date(date) + new Date().getTimezoneOffset() * 60000);

export const localDateString = (date: Date) =>
  new Date(+date - date.getTimezoneOffset() * 60000).toISOString();

export const debugRootStore = () => Object.fromEntries(Object.entries(store).map(i => [i[0], toJS(i[1])]))

export const debounce = (fn: Function, ms: number = 0) => {
  let timeoutId: any
  return (...args: any[]) => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => fn(...args), ms)
  }
}

export const validateEmail = (email: string): boolean => {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const loadScript = (src: string) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.onload = resolve
    script.onerror = reject
    script.src = src
    document.head.append(script)
  })
}

export const delay = (ms: number): Promise<void> => {
  return new Promise<void>(r => setTimeout(r, ms))
}

export const omitFields = (obj: TObjectAny, fields: string[]): TObjectAny => {
  const result = { ...obj }
  fields.forEach(f => delete result[f])
  return result
}

export const isRTLLocale = (locale: string) => {
  return 'ar arc ckb dv fa ha he khw ks ps sd ur uz_AF yi'.split(' ')
    .includes(locale.split('-')[0].toLowerCase());
}
