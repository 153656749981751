import { config as GBookingTestConfig } from '../config/domains/gbooking-test'
import { IDomainConfig } from '../interfaces/IConfig'

export function getDomainConfig(): IDomainConfig {
  // TODO определять по домену и подставлять конфиг из нужного файла config/domains/*
  return {
    CLIENT_ID: process.env.REACT_APP_CLIENT_ID ?? GBookingTestConfig.CLIENT_ID,
    API_SERVER: (process.env.REACT_APP_API_SERVER ?? GBookingTestConfig.API_SERVER).replace(/\/$/, ''),
    OAUTH_SERVER: (process.env.REACT_APP_OAUTH_SERVER ?? GBookingTestConfig.OAUTH_SERVER).replace(/\/$/, ''),
    REDIRECT_URI: process.env.REACT_APP_REDIRECT_URI ?? GBookingTestConfig.REDIRECT_URI,
  }
}
