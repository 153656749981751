import React, { useEffect } from 'react'
import '../assets/scss/base.scss'
import { AppRouter } from './AppRouter'
import { ErrorBoundary } from './shared/ErrorBoundary'
import { Meta } from './shared/Meta'
import { Toast } from 'antd-mobile'
import { rpc } from '../lib/Rpc'
import { t, setLocale } from '../config/i18n'
import { ConfigProvider } from 'antd-mobile'
import { isRTLLocale } from '../lib/utilsCommon'
import { store } from '../store'

export const App = () => {
  React.useEffect(() => rpc.onError(m => {
    Toast.show({ icon: 'fail', content: `RPC: ${m}` })
  }), [])

  const [localeUpdated, setLocaleUpdated] = React.useState(false);
  
  useEffect(() => {
    const profileLocale = store?.profile?.business?.business?.general_info?.language || 'en'
    const strLocale =
      store?.profile?.business?.business?.general_info?.language.split('-')[0].toLowerCase() || 'en'
    console.log('CHECK locale: ', profileLocale)
    if (t.locale !== strLocale) {
      console.log('Update locale to: ', profileLocale)
      setLocale(profileLocale)
      document.body.setAttribute('dir', isRTLLocale(t.locale) ? 'rtl' : 'ltr')
      setLocaleUpdated(!localeUpdated)
    }
  }, [store?.profile?.business?.business?.general_info?.language])
  
  return (
    <ErrorBoundary>
      <ConfigProvider locale={t.antd}>
        <Meta />
        <AppRouter />
      </ConfigProvider>
    </ErrorBoundary>
  )
}
