import React from 'react'
import styles from './Button.module.scss'
import cn from 'classnames'

export interface Props {
  children: React.ReactNode
  type?: 'solid' | 'outline'
  color?: 'primary' | 'success' | 'warning' | 'danger'
  fill?: boolean
  onClick?: (e: any) => void
}

export const Button = ({
  type='solid', color='primary', fill=false, onClick=(e: any)=>{}, children
}: Props) => {
  return (
    <div
      onClick={onClick}
      className={cn(styles.root, fill && styles.fill, styles[type], styles[color])}
    >
      {children}
    </div>
  )
}
