/* eslint-disable no-console */

/**
 * Wrapper for console.log
 * Can be extended with special logging libs or services like Sentry
 * Prefer to use window.logger.error() instead of console.error()
 */
const logger = {
  debug: (...args: any[]) => console.debug(new Date().toISOString(), ...args),
  error: (...args: any[]) => console.error(new Date().toISOString(), ...args),
  info:  (...args: any[]) => console.log(new Date().toISOString(), ...args),
  warn:  (...args: any[]) => console.log(new Date().toISOString(), ...args),
}

window.onerror = (msg, url, line, col, error) => {
  let extra = ''
  if (url) {
    extra = `${extra}\nUrl: ${url}`
  }
  if (line) {
    extra = `${extra}\nLine: ${line}`
  }
  if (col) {
    extra = `${extra}\nColumn: ${col}`
  }
  if (error) {
    extra = `${extra}\nError: ${error}`
  }

  logger.error(msg, extra)
  return true
}

export const loggerNamespace = (namespace: string) => ({
  debug(...msg: any[]) {
    logger.debug(`[${namespace}]`, ...msg)
  },
  info(...msg: any[]) {
    logger.info(`[${namespace}]`, ...msg)
  },
  warn(...msg: any[]) {
    logger.warn(`[${namespace}]`, ...msg)
  },
  error(...msg: any[]) {
    logger.error(`[${namespace}]`, ...msg)
  },
})

window.logger = logger
export { logger }
