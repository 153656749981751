import React from "react";
import { useDraggable, useDroppable } from "@dnd-kit/core";
import { CSS as dndCSS } from '@dnd-kit/utilities';
import styles from './DraggableAndDroppable.module.scss'
import cn from 'classnames'

interface Props {
  id: any,
  idActiveDraggable: any,
  children?: React.ReactNode,
  onClick?: (e: any) => void,
  className?: string,
}

export const DraggableAndDroppable = ({
  id,
  idActiveDraggable,
  children,
  onClick,
  className,
}: Props) => {
  // Draggable
  const { attributes, listeners, setNodeRef: setNodeDraggableRef, transform } = useDraggable({
    id,
  });

  // Droppable
  const { isOver, setNodeRef: setNodeDroppableRef } = useDroppable({
    id,
  });

  const styleDraggable = {
    // Outputs `translate3d(x, y, 0)`
    transform: dndCSS.Translate.toString(transform),
  };

  return (
    <div
      className={cn(
        styles.droppable,
        idActiveDraggable && styles.isDraggableSome,
        (isOver && idActiveDraggable !== id) && styles.isOver,
        idActiveDraggable === id && styles.isDraggableActiveCurrent
      )}
      ref={setNodeDroppableRef}
    >
      <button
        className={cn(
          styles.draggable,
          className,
        )}
        ref={setNodeDraggableRef}
        style={styleDraggable}
        {...listeners}
        {...attributes}
        onClick={onClick}
      >
        {children}
      </button>
    </div>
  )
}

export default DraggableAndDroppable;
